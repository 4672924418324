import { Box } from '@react-native-material/core';
import React from 'react';
import { View } from 'react-native';

import { viewReport } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import AuthNav from '@shared/components/layout/AuthNav';
import SideNav from '@shared/components/layout/SideNav';

import CreditScore from '../components/CreditScore';

export default function ViewReport() {
  return (
    <PageContainer>
      <AuthNav />
      <Box
        style={{ flexDirection: 'row', margin: 0, height: calculateHeight() }}>
        <SideNav isClient={false} />
      </Box>
      <Box style={{ margin: 10, flex: 1, height: calculateHeight() }}>
        <View style={viewReport.centeredContainer}>
          <CreditScore score={630} radius={100} strokeWidth={10} />
        </View>
      </Box>
    </PageContainer>
  );
}
