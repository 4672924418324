/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-raw-text */
import { Surface } from '@react-native-material/core';
import React from 'react';
import { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Button, DataTable, Text } from 'react-native-paper';
import { Link } from 'react-router-dom';

import DataTablePagination from '@shared/components/common/DataTablePagination';

export default function ClientsTable() {
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPageList] = useState([2, 3, 4]);
  const [itemsPerPage, onItemsPerPageChange] = useState(
    numberOfItemsPerPageList[0],
  );

  const [items] = useState([
    {
      key: 1,
      fName: 'Paty',
      lName: 'Morris',
      status: 'Locked',
      famCount: 12,
      action: true,
    },
  ]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, items.length);

  useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  return (
    <Surface elevation={24} style={styles.parentContainer}>
      <Text variant="titleLarge" style={{ padding: 10 }}>
        Manage Client
      </Text>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>First Name</DataTable.Title>
          <DataTable.Title>Last Name</DataTable.Title>
          <DataTable.Title>Lock/Unlock Status</DataTable.Title>
          <DataTable.Title>Family Member Counts</DataTable.Title>
          <DataTable.Title>Actions</DataTable.Title>
        </DataTable.Header>

        {items.slice(from, to).map(item => (
          <DataTable.Row key={item.key}>
            <DataTable.Cell>{item.fName}</DataTable.Cell>
            <DataTable.Cell>{item.lName}</DataTable.Cell>
            <DataTable.Cell>{item.status}</DataTable.Cell>
            <DataTable.Cell>{item.famCount}</DataTable.Cell>
            <DataTable.Cell>
              <Button mode="contained">
                <Link to="/view-report" style={{ color: 'white' }}>
                  MANAGE
                </Link>
              </Button>
            </DataTable.Cell>
          </DataTable.Row>
        ))}

        <DataTablePagination
          page={page}
          itemsPerPageList={numberOfItemsPerPageList}
          itemsPerPage={itemsPerPage}
          onPageChange={setPage}
          label={items.length}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          showFastPaginationControls
          selectPageDropdownLabel={'Rows per page'}
        />
      </DataTable>
    </Surface>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    margin: 20,
    padding: 10,
  },
});
