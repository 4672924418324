import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { Drawer, useTheme } from 'react-native-paper';
import { Link } from 'react-router-dom';

interface IsClientProps {
  isClient: boolean; // Define the type for the 'isClient' prop
}

import logo from '../../../assets/images/logoWhite.png';

export default function SideNav({ isClient }: IsClientProps) {
  const theme = useTheme();
  const [active, setActive] = React.useState('');

  const labelWrapperStyle = {
    fontSize: 14,
    fontWeight: 'bold',
  };
  return (
    <View style={{ backgroundColor: 'rgb(103, 124, 140)' }}>
      <Image source={logo} style={styles.image} />
      <Drawer.Section
        title="Menu"
        style={{ backgroundColor: theme?.colors?.onSecondary }}>
        {isClient ? (
          <>
            <Link to="/dashboard">
              <CustomDrawerItem
                label="Dashboard"
                active={active === 'first'}
                onPress={() => setActive('first')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            <Link to="/clients">
              <CustomDrawerItem
                label="Clients"
                active={active === 'second'}
                onPress={() => setActive('second')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            <CustomDrawerItem
              label="Accounts"
              active={active === 'third'}
              onPress={() => setActive('third')}
              labelWrapperStyle={labelWrapperStyle}
            />
            <Link to="/settings">
              <CustomDrawerItem
                label="Settings"
                active={active === 'fourth'}
                onPress={() => setActive('fourth')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            <CustomDrawerItem
              label="Documentations"
              active={active === 'fifth'}
              onPress={() => setActive('fifth')}
              labelWrapperStyle={labelWrapperStyle}
            />
          </>
        ) : (
          <>
            <Link to="/view-report">
              <CustomDrawerItem
                label="View Reports"
                active={active === 'first'}
                onPress={() => setActive('first')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            <Link to="/manage-family">
              <CustomDrawerItem
                label="Manage Family"
                active={active === 'second'}
                onPress={() => setActive('second')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            <Link to="/my-profile">
              <CustomDrawerItem
                label="My Profile"
                active={active === 'third'}
                onPress={() => setActive('third')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            <Link to="/manage-credit">
              <CustomDrawerItem
                label="Manage Credit"
                active={active === 'fourth'}
                onPress={() => setActive('fourth')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
            {/* <Link to="/lock-my-credit">
              <CustomDrawerItem
                label="Lock My Credit"
                active={active === 'fourth'}
                onPress={() => setActive('fourth')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link> */}
            <Link to="/dashboard">
              <CustomDrawerItem
                label="Logout of Client"
                active={active === 'fifth'}
                onPress={() => setActive('fifth')}
                labelWrapperStyle={labelWrapperStyle}
              />
            </Link>
          </>
        )}
      </Drawer.Section>
    </View>
  );
}

function CustomDrawerItem({
  label,
  active,
  onPress,
  labelWrapperStyle,
  children,
}: {
  label: string;
  active: boolean;
  onPress: () => void;
  labelWrapperStyle: any;
  children?: React.ReactNode;
}) {
  return (
    <Drawer.Item
      label={
        <View style={labelWrapperStyle}>
          {children || <Text>{label}</Text>}
        </View>
      }
      active={active}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  image: {
    height: 120,
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
  },
  // Unused styles
  // parentContainer: {
  //   marginBottom: 0,
  // },
  // sectionTitle: {
  //   fontSize: 18,
  //   fontWeight: 'bold',
  //   paddingVertical: 10,
  // },
});
