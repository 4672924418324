import { Surface, Text } from '@react-native-material/core';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export default function ClientCount() {
  return (
    <View style={styles.parentContainer}>
      <Surface elevation={24} style={styles.fillContainer}>
        <Text variant="h4" style={styles.childContainer}>
          129
        </Text>
        <Text variant="subtitle2" style={styles.childContainer}>
          Client Count
        </Text>
      </Surface>
    </View>
  );
}

const styles = StyleSheet.create({
  childContainer: {
    textAlign: 'center',
  },
  fillContainer: {
    borderRadius: 20,
    padding: 30,
  },
  parentContainer: {
    borderRadius: 20,
    padding: 10,
  },
});
