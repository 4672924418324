/* eslint-disable react-native/no-color-literals */
import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { Button, Text } from 'react-native-paper';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  const termsConditions = () => {
    Linking.openURL(
      'https://regalcredit.com/wp-content/uploads/2023/11/creditblock-regalcredit-com-terms-conditions.pdf',
    );
  };

  const privacyPolicy = () => {
    Linking.openURL(
      'https://regalcredit.com/wp-content/uploads/2023/11/Regal-Credit-Management-Privacy-Policy.pdf',
    );
  };

  return (
    <View style={styles.footer} testID="FooterParent">
      <View
        style={{ display: 'flex', flexDirection: 'row' }}
        testID="TermsAndPolicy">
        <Button mode="contained" onPress={termsConditions}>
          Terms & Conditions
        </Button>
        <Text style={styles.footerTextBullet} variant="displaySmall">
          ·
        </Text>
        <Button mode="contained" onPress={privacyPolicy}>
          Privacy Policy
        </Button>
      </View>
      <Text style={styles.footerTextRegal}>
        {`© 2018-${currentYear}, Regal Credit Management`}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
    backgroundColor: 'rgb(103, 124, 140)',
    borderTopColor: 'black',
    borderTopWidth: 1,
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    padding: 10,
    position: 'absolute',
    right: 0,
  },
  footerTextBullet: {
    alignItems: 'center',
    color: '#fff',
    display: 'flex',
    margin: 1,
    padding: 1,
  },
  footerTextRegal: {
    alignItems: 'center',
    color: '#c6e4ff',
    display: 'flex',
    margin: 1,
    padding: 1,
  },
});
