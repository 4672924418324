import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import LoadingSpinner from '@shared/components/layout/LoadingSpinner';

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.isAuthenticated && !auth?.isLoading) {
      auth?.signinRedirect?.();
    } else if (auth?.isAuthenticated) {
      navigate('/dashboard');
    }
  });
  return <LoadingSpinner />;
}
