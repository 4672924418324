import { Surface } from '@react-native-material/core';
import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet, Dimensions } from 'react-native';

interface ImageSliderProps {
  images: string[];
  interval?: number;
}

const { width } = Dimensions.get('window');

const ImageSlider: React.FC<ImageSliderProps> = ({
  images,
  interval = 3000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [images, interval]);

  return (
    <View style={styles.container}>
      <Surface elevation={24}>
        <Image source={{ uri: images[currentIndex] }} style={styles.image} />
        <View style={styles.pagination}>
          {images.map((_, index) => (
            <View
              key={index}
              style={[styles.dot, currentIndex === index && styles.activeDot]}
            />
          ))}
        </View>
      </Surface>
    </View>
  );
};

const DOT_SIZE = 8; // Adjust the dot size as needed
const DOT_MARGIN = 5; // Adjust the margin between dots as needed

const styles = StyleSheet.create({
  activeDot: {
    backgroundColor: 'black',
  },
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginTop: 10,
    padding: 10,
  },
  dot: {
    backgroundColor: 'gray',
    borderRadius: DOT_SIZE / 2,
    height: DOT_SIZE,
    marginHorizontal: DOT_MARGIN,
    width: DOT_SIZE,
  },
  image: {
    height: 300,
    resizeMode: 'cover',
    width: width * 0.8,
  },
  pagination: {
    flexDirection: 'row',
    marginTop: 10,
    padding: 10,
  },
});

export default ImageSlider;
