import { Surface } from '@react-native-material/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, TextInput, Text, ActivityIndicator } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';

import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { ProfileApiResponse } from '@type/profile.type';

function generateProfilePayload(): APIPayload {
  return {
    path: 'client/0015400000xqRF5AAM/profile',
    method: 'GET',
  };
}

export default function Profile() {
  const [requestData, setRequestData] = useState<ProfileApiResponse | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      const token = auth.user?.access_token;
      const payload = generateProfilePayload();
      const response = await requestAPI(payload, token);
      if (response !== undefined) {
        setRequestData(response.data);
        setLoading(false);
      } else {
        setRequestData(null);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Text style={styles.headContainer} variant="headlineMedium">
        Profile
      </Text>
      <Surface elevation={24} style={styles.parentContainer}>
        {loading ? ( // Conditional rendering of ActivityIndicator
          <ActivityIndicator
            animating={true}
            size="large"
            style={{ marginTop: 20 }}
          />
        ) : (
          <>
            <View style={styles.textInputContainer}>
              <TextInput
                label="First Name"
                style={styles.textInput}
                value={requestData?.data?.FirstName ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="Last Name"
                style={styles.textInput}
                value={requestData?.data?.LastName ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <View style={styles.emptyCell} />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="Date of Birth"
                style={styles.textInput}
                value={requestData?.data?.DoB ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="SSN"
                style={styles.textInput}
                value={requestData?.data?.SSN ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <View style={styles.emptyCell} />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="Address Line 1"
                style={styles.textInput}
                value={requestData?.data?.Address.Street ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <View style={styles.emptyCell} />
              <View style={styles.emptyCell} />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="City"
                style={styles.textInput}
                value={requestData?.data?.Address.City ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="State"
                style={styles.textInput}
                value={requestData?.data?.Address.State ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="Country"
                style={styles.textInput}
                value={requestData?.data?.Address.Country ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
            </View>
            <View style={styles.textInputContainer}>
              <TextInput
                label="Phone Number"
                style={styles.textInput}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="Mobile Number"
                style={styles.textInput}
                value={requestData?.data?.Mobile ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
              <TextInput
                label="Email Address"
                style={styles.textInput}
                value={requestData?.data?.Email ?? ''}
                theme={{
                  colors: { primary: 'black', onSurfaceVariant: 'black' },
                }}
              />
            </View>
            <View style={styles.buttonContainer}>
              <Button
                mode="contained"
                style={[styles.button, { backgroundColor: 'rgb(129 43 43)' }]}>
                Cancel
              </Button>
              <Button
                mode="contained"
                style={[
                  styles.button,
                  { backgroundColor: 'rgb(103, 124, 140)' },
                ]}>
                Save
              </Button>
            </View>
          </>
        )}
      </Surface>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    marginRight: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  emptyCell: {
    flex: 1,
  },
  headContainer: {
    borderRadius: 20,
    marginLeft: 20,
    marginTop: 20,
    padding: 10,
  },
  parentContainer: {
    borderRadius: 20,
    margin: 20,
    padding: 10,
  },
  textInput: {
    backgroundColor: 'white',
    flex: 1,
    marginHorizontal: 5,
  },
  textInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
});
