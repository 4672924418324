/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React, { useEffect } from 'react';
import { View, Text } from 'react-native';

import { main } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import AuthNav from '@shared/components/layout/AuthNav';
import SideNav from '@shared/components/layout/SideNav';
import { Client } from '@type/client.type';

import ClientsTable from '../components/ClientsTable';
import { useClient } from '../hooks/clientHook';

const Clients = () => {
  const { state, getClient } = useClient();

  const [clientData, setClientData] = React.useState({} as Client);
  const [_loading, setLoading] = React.useState(true);
  const [_error, setError] = React.useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getClient();
        if (data) setClientData(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  return (
    <PageContainer>
      <AuthNav />
      <Box
        style={{
          flexDirection: 'row',
          height: calculateHeight(),
          margin: 0,
        }}>
        <SideNav isClient={true} />
      </Box>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={main.centeredContainer}>
          {/* <Text>State: {JSON.stringify(state)} </Text>
          <Text>{clientData.name}</Text> */}
          <ClientsTable />
        </View>
      </Box>
    </PageContainer>
  );
};

export default Clients;
