import { KC_CLIENT_ID, KC_REALM, KC_REDIRECT_URL_WEB } from '@env';
import { Surface, Text } from '@react-native-material/core';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Link } from 'react-router-dom';

export default function ChangePassword() {
  return (
    <View style={styles.parentContainer}>
      <Surface elevation={24} style={styles.fillContainer}>
        <Link
          to={`https://sso.stg.regalcredit.com/auth/realms/${KC_REALM}/protocol/openid-connect/auth?client_id=${KC_CLIENT_ID}&redirect_uri=${KC_REDIRECT_URL_WEB}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`}>
          <Text variant="subtitle2" style={styles.childContainer}>
            Change Password
          </Text>
        </Link>
      </Surface>
    </View>
  );
}

const styles = StyleSheet.create({
  childContainer: {
    textAlign: 'center',
  },
  fillContainer: {
    borderRadius: 20,
    padding: 30,
  },
  parentContainer: {
    borderRadius: 20,
    padding: 10,
  },
});
