import * as React from 'react';
import { DataTable } from 'react-native-paper';

interface DataTablePaginationProps {
  page: number;
  itemsPerPageList: number[];
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  label: number;
  numberOfItemsPerPage: number;
  onItemsPerPageChange: (itemsPerPage: number) => void; // Provide a type annotation here
  showFastPaginationControls: boolean;
  selectPageDropdownLabel: string;
}

export default function DataTablePagination({
  page,
  itemsPerPageList,
  itemsPerPage,
  onPageChange,
  label,
  numberOfItemsPerPage,
  onItemsPerPageChange,
  showFastPaginationControls,
  selectPageDropdownLabel,
}: DataTablePaginationProps) {
  return (
    <DataTable.Pagination
      page={page}
      numberOfPages={Math.ceil(label / itemsPerPage)}
      onPageChange={onPageChange}
      label={`${page * itemsPerPage + 1}-${numberOfItemsPerPage} of ${label}`}
      numberOfItemsPerPageList={itemsPerPageList}
      numberOfItemsPerPage={itemsPerPage}
      onItemsPerPageChange={onItemsPerPageChange}
      showFastPaginationControls={showFastPaginationControls}
      selectPageDropdownLabel={selectPageDropdownLabel}
    />
  );
}
