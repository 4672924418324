import { AppBar } from '@react-native-material/core';
import React from 'react';

export default function Login() {
  const titleStyle = {
    fontSize: 30, // Change the font size to your desired value
  };

  return (
    <AppBar
      title="Partner Portal"
      style={{
        backgroundColor: 'rgb(103, 124, 140)',
        shadowColor: 'rgb(0 0 0)',
        shadowOffset: { width: 7, height: 5 },
        shadowOpacity: 0.8,
        shadowRadius: 6,
        borderBottomWidth: 2,
        borderBottomColor: '#0000007d',
      }}
      titleStyle={titleStyle}
    />
  );
}
