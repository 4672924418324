/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
import { Surface } from '@react-native-material/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Button, DataTable, Text } from 'react-native-paper';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

import DataTablePagination from '@shared/components/common/DataTablePagination';
import { requestAPI } from '@shared/utils/apiUtil';
import { APIPayload } from '@type/api.type';
import { FamilyTableApiResponse } from '@type/family.type';

function generateFamilyPayload(): APIPayload {
  return {
    path: 'client/0015400000xqRF5AAM/family',
    method: 'GET',
  };
}

export default function ClientsTable() {
  const [page, setPage] = useState<number>(0);
  const [numberOfItemsPerPageList] = useState([4, 5, 6, 7]);
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      const token = auth?.user?.access_token;
      const payload = generateFamilyPayload();
      const response = await requestAPI(payload, token);
      if (response !== undefined) {
        const familyData = renderFamilyData(response.data);
        setItems(familyData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  function renderFamilyData(data: FamilyTableApiResponse) {
    const familyData = [];
    const familyDataObj = { data };
    familyData.push({
      key: 'spouse',
      fullName: familyDataObj.data.data.spouse.fullName,
      relationship: 'Spouse',
      profile: familyDataObj.data.data.spouse.profile,
    });
    familyDataObj.data.data.dependents.forEach((dependent, index) => {
      familyData.push({
        key: 'dependent',
        fullName: dependent.fullName,
        relationship: 'Dependent',
        profile: dependent.profile,
      });
    });
    return familyData;
  }

  const [itemsPerPage, onItemsPerPageChange] = useState(
    numberOfItemsPerPageList[0],
  );

  useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, items.length);

  return (
    <Surface elevation={24} style={styles.parentContainer}>
      <Text variant="titleLarge" style={{ padding: 10 }}>
        Manage Family
      </Text>
      {loading ? (
        <ActivityIndicator
          testID="loading-indicator"
          animating={true}
          size="large"
          style={{ marginTop: 20 }}
        />
      ) : (
        <>
          <DataTable testID="DataTable">
            <DataTable.Header>
              <DataTable.Title>First Name</DataTable.Title>
              <DataTable.Title>Last Name</DataTable.Title>
              <DataTable.Title>Relationship</DataTable.Title>
              <DataTable.Title>Actions</DataTable.Title>
            </DataTable.Header>

            {items.slice(from, to).map((item, index) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>{item.fullName}</DataTable.Cell>
                <DataTable.Cell>{item.profile}</DataTable.Cell>
                <DataTable.Cell>{item.relationship}</DataTable.Cell>
                <DataTable.Cell>
                  <Button mode="contained">
                    <Link
                      to={
                        {
                          pathname: '/family-profile',
                          search: `profile=${item.profile}`,
                        } as any
                      }
                      style={{ textDecoration: 'none', color: 'white' }}>
                      MANAGE
                    </Link>
                  </Button>
                </DataTable.Cell>
              </DataTable.Row>
            ))}

            <DataTablePagination
              page={page}
              itemsPerPageList={numberOfItemsPerPageList}
              itemsPerPage={itemsPerPage}
              onPageChange={setPage}
              label={items.length}
              numberOfItemsPerPage={itemsPerPage}
              onItemsPerPageChange={onItemsPerPageChange}
              showFastPaginationControls
              selectPageDropdownLabel={'Rows per page'}
            />
          </DataTable>
        </>
      )}
    </Surface>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    color: 'black',
    margin: 20,
    padding: 10,
  },
});
