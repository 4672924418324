/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@react-native-material/core';
import React from 'react';
import { View } from 'react-native';
import { Divider, Text } from 'react-native-paper';

import { settings } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import AuthNav from '@shared/components/layout/AuthNav';
import SideNav from '@shared/components/layout/SideNav';

import ChangePassword from '../components/ChangePassword';

const Settings = () => {
  return (
    <PageContainer>
      <AuthNav />
      <Box
        style={{
          flexDirection: 'row',
          height: calculateHeight(),
          margin: 0,
        }}>
        <SideNav isClient={true} />
      </Box>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={settings.centeredContainer}>
          <Text variant="titleLarge">Security</Text>
          <Divider style={{ width: '100%', height: 5, marginTop: 5}} />
          <ChangePassword />
        </View>
      </Box>
    </PageContainer>
  );
};

export default Settings;
