import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, LayoutChangeEvent } from 'react-native';

export default function PageContainer({ children }) {
  const [authNavHeight, setAuthNavHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  const handleAuthNavLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setAuthNavHeight(height);
  };

  useEffect(() => {
    // Calculate contentHeight after authNavHeight is set
    setContentHeight(Dimensions.get('window').height - authNavHeight);
  }, [authNavHeight]);

  const composedStyles = StyleSheet.compose(styles.parentContainer, {
    height: contentHeight,
  });

  return (
    <>
      <View onLayout={handleAuthNavLayout}>{children[0]}</View>
      <View style={composedStyles}>{children.slice(1)}</View>
    </>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: 0,
    textAlign: 'center',
  },
});
