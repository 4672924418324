import { useContext } from 'react';

import { CreditLockContext } from './creditLockContext';

export const useCreditLock = () => {
  const context = useContext(CreditLockContext);
  if (!context) {
    throw new Error('useCreditLock must be used within a CreditLockProvider');
  }
  return context;
};
