/* eslint-disable react-native/no-raw-text */
import React from 'react';
import { PaperProvider } from 'react-native-paper';
import { AuthProvider } from 'react-oidc-context';

import { config } from '@configs/app.config';

import { lightTheme as defaultTheme } from './assets/theme/theme';
import Web from './navigations/Web';
import Footer from './shared/components/layout/Footer';

const { issuer, clientId, redirectUrl } = config.kc;

const oidcConfig = {
  authority: issuer,
  client_id: clientId,
  redirect_uri: redirectUrl.web,
  loadUserInfo: true,
};
function MainComponent() {
  return (
    <AuthProvider {...oidcConfig}>
      <PaperProvider theme={defaultTheme}>
        <React.Fragment>
          <style type="text/css">{`
              @font-face {
                font-family: 'MaterialCommunityIcons';
                src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
              }
            `}</style>
          <Web />
          <Footer />
        </React.Fragment>
      </PaperProvider>
    </AuthProvider>
  );
}

export default function Main() {
  return <MainComponent />;
}
