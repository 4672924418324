import { Box } from '@react-native-material/core';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { dashboard } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import AuthNav from '@shared/components/layout/AuthNav';
import SideNav from '@shared/components/layout/SideNav';

import ClientCount from '../components/ClientCount';
import Greetings from '../components/Greetings';
import MarketingWidget from '../components/MarketingWidget';

const images = [
  'https://drive.google.com/uc?export=view&id=1W1kreUU8GASa8XnYKi8PnjTcTVSAlscE',
  'https://drive.google.com/uc?export=view&id=1d0BOinmSknG3UTk9IyA4fztXv8i04Uzc',
  'https://drive.google.com/uc?export=view&id=15c082cEQHg2Sqap0A2C_sIRO3aCjoQdt',
];

export default function Dashboard() {
  return (
    <PageContainer>
      <AuthNav />
      <Box
        style={{ flexDirection: 'row', height: calculateHeight(), margin: 0 }}>
        <SideNav isClient={true} />
      </Box>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={dashboard.centeredContainer}>
          <Greetings />
          <ClientCount />
          <MarketingWidget images={images} />
        </View>
      </Box>
    </PageContainer>
  );
}
