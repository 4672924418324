import { Box } from '@react-native-material/core';
import React from 'react';
import { View } from 'react-native';

import { main } from '@assets/styles/pagesStyle';
import { calculateHeight } from '@shared/components/common/helpers/displayDimensions';
import PageContainer from '@shared/components/common/PageContainer';
import AuthNav from '@shared/components/layout/AuthNav';
import SideNav from '@shared/components/layout/SideNav';

import FamilyTable from '../components/FamilyTable';

export default function ManageFamily() {
  return (
    <PageContainer>
      <AuthNav />
      <Box
        style={{ flexDirection: 'row', height: calculateHeight(), margin: 0 }}>
        <SideNav isClient={false} />
      </Box>
      <Box style={{ height: calculateHeight(), margin: 10, flex: 1 }}>
        <View style={main.centeredContainer}>
          <FamilyTable />
        </View>
      </Box>
    </PageContainer>
  );
}
