import { Text } from '@react-native-material/core';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';

export default function Greetings() {
  const theme = useTheme();

  return (
    <View style={styles.parentContainer}>
      <Text variant="h4">Hi, Jopye</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    padding: 10,
    textAlign: 'center',
  },
});
