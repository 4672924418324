import { Surface } from '@react-native-material/core';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { Svg, Circle, ForeignObject } from 'react-native-svg';

interface ICreditScore {
  score: number;
  radius: number;
  strokeWidth: number;
}

export default function CreditScore({
  score,
  radius,
  strokeWidth,
}: ICreditScore) {
  const circumference = 2 * Math.PI * radius;
  const progress = (score / 1000) * circumference;
  const remaining = circumference - progress;

  return (
    <Surface elevation={24} style={styles.fillContainer}>
      <View style={styles.fillContainer}>
        <Text variant="headlineMedium">
          You are currently logged in as John Doe.
        </Text>
      </View>
      <View style={styles.parentContainer}>
        <Svg height={radius * 2} width={radius * 2}>
          <Circle
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            fill="transparent"
            stroke="#d1d1d1"
            strokeWidth={strokeWidth}
          />
          <Circle
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            fill="transparent"
            stroke="#007aff"
            strokeWidth={strokeWidth}
            strokeDasharray={`${progress} ${remaining}`}
            strokeLinecap="round"
          />
          <ForeignObject
            x={radius - 20}
            y={radius - 20}
            width={radius}
            height={30}>
            <Text
              style={{
                fontSize: 30,
                textAlign: 'center',
                color: 'black',
              }}>
              {score}
            </Text>
          </ForeignObject>
        </Svg>
      </View>
      <View>
        <Text variant="displaySmall">Your Credit Score</Text>
      </View>
    </Surface>
  );
}

const styles = StyleSheet.create({
  fillContainer: {
    alignItems: 'center',
    borderRadius: 20,
    marginTop: 20,
    padding: 10,
    textAlign: 'left',
  },
  parentContainer: {
    borderRadius: 20,
    margin: 20,
    padding: 10,
    textAlign: 'left',
  },
});
